import { User } from '../api/services';

export const largeNumberFormatter = (
  user: User | undefined,
  num: number | undefined,
  digits?: number,
  showCompact?: boolean,
) =>
  num != null
    ? Intl.NumberFormat(user?.language, {
        notation: showCompact ? 'compact' : undefined,
        maximumSignificantDigits: digits,
        minimumSignificantDigits: digits,
      }).format(num)
    : 0;

// Also known as the moneyFormatter
export const getMoney = (user: User, num: number | undefined, digits?: number, showCompact?: boolean) =>
  num != null
    ? Intl.NumberFormat(user.language, {
        notation: showCompact ? 'compact' : undefined,
        style: 'currency',
        currency: 'EUR',
        maximumSignificantDigits: digits,
      }).format(num)
    : 0;
