import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterHorsepower = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.HP_FROM, AppSearchParamKeys.HP_TO]}
    defaultValue={[0, 600]}
    min={0}
    labelTranslationKey="POWER"
  />
);
