import { Stack } from '@mui/material';
import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { AppSearchParamKeys } from '@japieglobal/shared/src';
import { BoxFlexColumn, CarTableCell } from '../result-table-styles';
import { ResultTurnoverButton } from '../../../taxation-and-results-shared/buttons/result-turnover-button';
import { compareMemoProps } from '../../../utils/compare-react-props';

interface ResultTrendCellProps {
  car: CarResult;
}
export const ResultTrendCell = React.memo(
  ({ car }: ResultTrendCellProps) => {
    return (
      <CarTableCell style={{ width: 100 }}>
        <Stack direction="row" spacing={1}>
          <BoxFlexColumn>
            <div>
              {car.stat?.turnover_int != null && (
                <ResultTurnoverButton car={car} column="itr" searchParamKey={AppSearchParamKeys.SORT_TURNOVER_INT} />
              )}
              <ResultTurnoverButton car={car} column="etr" searchParamKey={AppSearchParamKeys.SORT_TURNOVER_EXT} />
              {car.apr != null && (
                <ResultTurnoverButton car={car} searchParamKey={AppSearchParamKeys.SORT_APR} column="apr" />
              )}
            </div>
          </BoxFlexColumn>
        </Stack>
      </CarTableCell>
    );
  },
  compareMemoProps(['car']),
);
