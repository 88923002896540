import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ColumnTextAlignment, GridColumnType } from '@japieglobal/shared/src/components';

export interface CarWindowColumn {
  field: keyof CarResult;
  flex?: number;
  headerName?: string;
  type: GridColumnType;
  columnType: string;
  clickName?: string;
  align?: ColumnTextAlignment;
  minWidth?: number;
  cellClassName?: string;
  headerClassName?: string;
  headerType?: string;
  headerAlign?: ColumnTextAlignment;
  headerDescription?: string;
}
export const CarWindowColumns: CarWindowColumn[] = [
  { field: 'image', type: 'string', columnType: 'image', flex: 2.5, cellClassName: 'noPadding' },
  { field: 'id', type: 'string', columnType: 'logo', flex: 2, cellClassName: 'noPadding' },
  {
    field: 'price',
    headerName: 'PRICE',
    type: 'string',
    columnType: 'price',
    flex: 2,
  },
  {
    field: 'stock_days',
    headerName: 'DAYS',
    type: 'string',
    columnType: 'basic',
    minWidth: 63,
    headerDescription: 'STAND_TIME',
  },
  { field: 'stat', headerName: 'DELTA', type: 'string', columnType: 'delta' },
  {
    field: 'build',
    headerName: 'BUILD_YEAR_ABR',
    type: 'string',
    columnType: 'click',
    clickName: 'clickBuild',
  },
  {
    field: 'model_year',
    headerName: 'MODEL_YEAR_ABR',
    type: 'string',
    columnType: 'click',
    clickName: 'clickModelYear',
  },
  { field: 'mileage', headerName: 'KM', type: 'string', columnType: 'mileage' },
  {
    field: 'hp',
    headerName: 'PK',
    type: 'string',
    columnType: 'click',
    clickName: 'clickHP',
    flex: 0.5,
  },
  {
    field: 'options',
    headerName: 'OPTIONS',
    type: 'string',
    flex: 15,
    columnType: 'options',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'equipment',
    type: 'string',
    flex: 4,
    columnType: 'equipment',
    align: 'left',
  },
  {
    field: 'url',
    type: 'string',
    columnType: 'redirect',
    flex: 0.5,
    minWidth: 25,
  },
  {
    field: 'country',
    type: 'string',
    columnType: 'flag',
    flex: 0.5,
    minWidth: 25,
  },
  {
    field: 'is_banned',
    type: 'string',
    columnType: 'trash',
    flex: 0.5,
    headerClassName: 'noPadding',
    headerType: 'trash',
    minWidth: 25,
  },
];
