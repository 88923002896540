import React, { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';
import logo from '../../../../../public/images/logo.png';
import { FilterResetButton } from './filter-reset-button';

export interface FiltersWrapperProps {
  filterCount?: number;
  onClick?: () => void;
  hideResetAllButton?: boolean;
  extraButtons?: JSX.Element[];
}

const ButtonsWrapper = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  marginLeft: '20%',
  rowGap: '10px',
});

const ImageStyled = styled(`img`)(({ theme }) => ({
  width: '180px',
  objectFit: 'cover',
  height: '100px',
  marginLeft: '20%',
  filter: theme.imageFilter?.darkFilter,
}));

const HeaderWrapper = styled(`div`)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  zIndex: 100,
  backgroundColor: theme.palette.background.default,
  width: '125%',
  marginBottom: '40px',
  marginLeft: '-25%',
}));

export const FiltersWrapper = ({
  children,
  filterCount,
  onClick,
  hideResetAllButton = false,
  extraButtons,
}: PropsWithChildren<FiltersWrapperProps>) => {
  const JPcarsLogo = () => (
    <a style={{ marginBottom: '20px', width: 'fit-content', height: 'fit-content' }} href="/">
      <ImageStyled src={logo} alt="" />
    </a>
  );

  return (
    <>
      <HeaderWrapper>
        <JPcarsLogo />
        <ButtonsWrapper>
          {!hideResetAllButton && <FilterResetButton filterCount={filterCount} onClick={onClick} />}
          {extraButtons}
        </ButtonsWrapper>
      </HeaderWrapper>
      <Box display={'flex'} flexDirection={'column'}>
        {children}
      </Box>
    </>
  );
};
