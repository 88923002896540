export enum NumberAppSearchParam {
  BUILD = 'build',
  EXTRA_COSTS = 'extra_costs',
  EXTRA_MARGIN = 'extra_margin',
  HP = 'hp',
  HP_FROM = 'hp_from',
  HP_TO = 'hp_to',
  BUILD_YEAR_FROM = 'build_year_from',
  BUILD_YEAR_TO = 'build_year_to',
  MODEL_YEAR_FROM = 'model_year_from',
  MODEL_YEAR_TO = 'model_year_to',
  MODEL_YEAR = 'model_year',
  MILEAGE = 'mileage',
  MILEAGE_FROM = 'mileage_from',
  MILEAGE_TO = 'mileage_to',
  STOCK_DEALER = 'stock_minimum',
  SOLD_DEALER = 'sold_minimum',
  STOCK_MARKET = 'stock_market_minimum',
  SOLD_MARKET = 'sold_market_minimum',
  PRICE_FROM = 'price_from',
  PRICE_TO = 'price_to',
  ETR = 'stat_turnover_ext',
  ITR = 'stat_turnover_int',
  APR = 'apr',
  ETR_FROM = 'etr_from',
  ITR_FROM = 'itr_from',
  APR_FROM = 'apr_from',
  ETR_TO = 'etr_to',
  ITR_TO = 'itr_to',
  APR_TO = 'apr_to',
  STOCK_DAYS_FROM = 'stock_days_from',
  STOCK_DAYS_TO = 'stock_days_to',
  WINDOW_SIZE_FROM = 'window_size_from',
  WINDOW_SIZE_TO = 'window_size_to',
  LAST_DISCOUNT_FROM = 'last_discount_from',
  LAST_DISCOUNT_TO = 'last_discount_to',
  DISCOUNT_FROM = 'discount_from',
  DISCOUNT_TO = 'discount_to',
  DISCOUNT_PERCENTAGE_FROM = 'discount_percentage_from',
  DISCOUNT_PERCENTAGE_TO = 'discount_percentage_to',
  CC = 'cc',
  HP_NON_ELECTRIC = 'hp_non_electric',
  OWN_SUPPLY_CAR_ID = 'own_supply_car_id',
  PAGE_INDEX = 'page_index',
  PAGE_SIZE = 'page_size',
  ID = 'id',
  MATURITY_MONTHS = 'maturity_months',
  MILEAGE_AT_MATURITY = 'mileage_at_maturity',
  PRICE_DISCOUNT = 'priceDiscount',
  STOCK_DAYS = 'stock_days',
  START_PRICE = 'start_price',
  MAX_ITERATIONS = 'max_iterations',
  AUCTION_PORTAL_DAYS = 'days',
  BMP_FROM = 'bmp_from',
  BMP_TO = 'bmp_to',
  SOLD_SINCE_FROM = 'sold_since_from',
  SOLD_SINCE_TO = 'sold_since_to',
  HORIZON = 'horizon',
}
