import React from 'react';
import { Box, Grid2, Paper } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types';
import { CarWindowResult } from '@japieglobal/shared/src/api/services';
import { TaxationCarView } from './taxation-car-view';
import { ResultOtherCountries } from './result-other-countries/result-other-countries';
import { ResultTopDealers } from '../../taxation-and-results-shared/result-top-dealers';
import { LoadingWrapper } from '@japieglobal/shared';

interface TaxationTopViewProps {
  car: CarResult | undefined;
  windowResults: { [country: string]: CarWindowResult | undefined };
  currentCountry: string;
  selectedCountry: string;
  clickDealers: { [country: string]: string | undefined };
  setClickDealers: (clickDealer: { [country: string]: string | undefined }) => void;
  setSelectedCountry: (selectedCountry: string) => void;
  windowIsLoading: { [country: string]: boolean };
  isLoadingCarInformation: boolean;
}

export const TaxationTopView = ({
  car,
  windowResults,
  selectedCountry,
  setSelectedCountry,
  clickDealers,
  setClickDealers,
  windowIsLoading,
  isLoadingCarInformation,
}: TaxationTopViewProps) => (
  <LoadingWrapper isLoading={isLoadingCarInformation}>
    <Paper sx={{ padding: '10px' }}>
      <TaxationCarView car={car} />
      <Grid2 container marginTop={2} flexGrow={1}>
        <Grid2 size={11}>
          <ResultOtherCountries
            windowIsLoading={windowIsLoading}
            windowResults={windowResults}
            setSelectedCountry={setSelectedCountry}
          />
        </Grid2>
        <Grid2 size={1}>
          <Box mr={2} display="flex" justifyContent="flex-end" flexGrow={1}>
            {windowResults[selectedCountry]?.top_dealers || clickDealers[selectedCountry] ? (
              <ResultTopDealers
                dealers={windowResults[selectedCountry]?.top_dealers ?? []}
                selected={clickDealers[selectedCountry]}
                click={(dealer) => setClickDealers({ ...clickDealers, [selectedCountry]: dealer })}
              />
            ) : (
              <div />
            )}
          </Box>
        </Grid2>
      </Grid2>
    </Paper>
  </LoadingWrapper>
);
