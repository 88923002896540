import React, { useCallback, useContext, useRef } from 'react';
import { DealerButton } from './buttons/dealer-button';
import { TranslationButton } from './buttons/translation-button';
import { HintsButton } from '../../hints/hints-button';
import { Grid2 } from '@mui/material';
import { TaxationButton } from './buttons/taxation-button';
import { FileValuationButton } from './buttons/file-valuation-button';
import { HomeButton } from './buttons/home-button';
import { SettingsButton } from './buttons/settings-button';
import LogoutIcon from '@mui/icons-material/Logout';
import { MenuButton } from '../menu-button';
import { ThemeButton } from './buttons/theme-button';
import { logout, UserRoles } from '../../../api/services';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Siren from './police-car-siren.gif';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import sound from './Please-Evacuate-this-Building.mp3';
import { useSnackbarErrorHandler } from '../../../hooks';
import { UserContext } from '../../../user-context';

interface TopRowProps {
  internal: boolean;
  showHintsButton?: boolean;
}

export const FirstBar = ({ internal, showHintsButton = false }: TopRowProps) => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { user, setUser } = useContext(UserContext);

  const logoutAction = useCallback(() => {
    logout()
      .then(() => setUser(undefined))
      .catch(snackbarErrorHandler);
  }, [setUser, snackbarErrorHandler]);

  const audio = useRef(new Audio(sound));
  const isPlaying = useRef(false);
  const playAudio = () => {
    if (isPlaying.current) {
      isPlaying.current = false;
      audio.current.pause();
    } else {
      isPlaying.current = true;
      audio.current.play();
    }
  };

  return (
    <Grid2 height={40} gap="10px" display="flex" flexDirection="row" alignSelf="flex-end">
      {user.role != UserRoles.SCRAPE_USER && (
        <>
          <MenuButton
            hoverTitle={'ALERT'}
            display={user.notifications.includes('super_admin_master_mode')}
            onClick={playAudio}
          >
            <img src={Siren} alt="loading" width={65} />
          </MenuButton>

          <HomeButton internal={internal} user={user} />
          <TaxationButton internal={internal} user={user} />
          <FileValuationButton internal={internal} user={user} />
          <DealerButton user={user} />
          <SettingsButton internal={internal} user={user} />
        </>
      )}
      <TranslationButton />
      {showHintsButton && user.role != UserRoles.SCRAPE_USER && <HintsButton />}
      <ThemeButton user={user} internal={internal} />
      <MenuButton hoverTitle={'LOGOUT'} onClick={logoutAction}>
        <LogoutIcon name="logout" />
      </MenuButton>
    </Grid2>
  );
};
