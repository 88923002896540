import { instance } from './instance';

export const sendMessage = async (body: { car_id: string; content: string }) =>
  instance.post(`/api/message`, body).then((res) => res.data);

export enum CarHeader {
  MODEL = 'model',
  MAKE = 'make',
  FUEL = 'fuel',
  GEAR = 'gear',
  BODY = 'body',
  HP = 'hp',
  MODEL_YEAR = 'model_year',
  BUILD = 'build',
  MILEAGE = 'mileage',
  OPTIONS = 'options',
}
export interface CarHeaderProps {
  type: CarHeader;
  fuel?: string;
  gear?: string;
  make?: string;
  model?: string;
  body?: string;
  build?: number;
  hp?: number;
  dealer_specific?: boolean; // backend default is true
  include_generic_commercial?: boolean; // backend default is false
}

export interface CarInformationResult {
  license_plate: string;
  mileage: number;
  make: string;
  model: string;
  body: string;
  fuel: string;
  gear: string;
  build: number;
  hp: number;
  kw: number;
  specials: string;
  cc: number;
  price_local: number;
  equipment_navigation: true;
  equipment_xenon: true;
  equipment_airco: true;
  equipment_alloy_wheels: true;
  equipment_roof: true;
  equipment_clima: true;
  equipment_leather: true;
  equipment_four_doors: true;
  four_doors: true;
  url: string;
  price_catalog: number;
  date_apk: string;
  date_change_owner: string;
  stat_apk_days: number;
  stock_days_rdw: number;
  stat_local_car: true;
  model_year: number;
  specials_info: string;
  co2: number;
  co2_wltp: number;
  co2_nedc: number;
  error_code: string;
  error_message: string;
  color: string;
  location: string;
  country: string;
  rdw_bpm: number;
  standard_options: string;
  extracted_options: string;
  options: string;
  vin: string;
  bpm_date: string;
  is_commercial: true;
}
export type CarInformationQuery = {
  licensePlate?: string;
  make?: string;
  model?: string;
  body?: string;
  fuel?: string;
  gear?: string;
  build?: number;
  hp?: number;
  options?: string;
  model_year?: number;
  equipment_four_doors?: boolean;
};
export const carHeader = async (props: CarHeaderProps): Promise<{ headers: (string | number)[] }> =>
  instance.get('/api/headers', { params: props }).then((res) => res.data);

export const getCarInformation = async (props: CarInformationQuery): Promise<CarInformationResult> => {
  const body = {
    config: { use_paid_options: true, use_paid_call: true },
    car: props.licensePlate ? { license_plate: props.licensePlate } : props,
  };
  return instance.post(`extract/extract`, body).then((res) => res.data);
};
