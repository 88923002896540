export function formatKNotation(val: number | undefined) {
  if (val !== undefined && Number.isFinite(val)) {
    return `${Math.round(val / 100) / 10}k`.replace('.', ',');
  }
  return '---';
}

export function formatThousands(val: number | undefined) {
  if (val !== undefined && Number.isFinite(val)) {
    return val.toLocaleString('nl');
  }
  return '---';
}

export function formatDecimalAsPercent(val: number | undefined) {
  if (val !== undefined && Number.isFinite(val)) {
    return `${Math.round(val * 100)}%`;
  }
  return '---';
}
