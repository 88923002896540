import React from 'react';
import { TFunction } from 'i18next';
import { styled } from '@mui/system';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { User } from '@japieglobal/shared/src/api/services';
import { useInfoColorsStyles } from '../../cars/results/info-colors-styles';
import { formatThousands } from '../../utils/number-format';
import { capitalizeFirstLetter } from '../../utils/string-first-letter-capital';

const ColorSpan = styled('span')({
  position: 'absolute',
  top: '2px',
  left: '2px',
  width: '5px',
  height: '5px',
});

const DivRelativePosition = styled(`div`)({
  position: 'relative',
  display: 'flex',
});

const RankingSpan = styled(`span`)({
  fontWeight: 400,
  paddingLeft: '10px',
});

const SpacerDiv = styled(`div`)({ height: '10px' });

const addSpacer = (arr: JSX.Element[][]) => {
  arr.push([<SpacerDiv />, <SpacerDiv />]);
};

interface ResultMoreInfoPopupContentProps {
  user: User;
  car: CarResult;
  t: TFunction;
  styles: ReturnType<typeof useInfoColorsStyles>;
}
export const resultMoreInfoPopupContent = ({ user, car, t, styles }: ResultMoreInfoPopupContentProps) => {
  const result: Array<Array<any>> = [];

  result.push([t('RANKING'), '']);
  if (car.rank) {
    result.push([<RankingSpan>{t('TARGET')}</RankingSpan>, car.rank.target]);
    result.push([<RankingSpan>{t('CURRENT')}</RankingSpan>, car.rank.current]);
    result.push([<RankingSpan>{t('TOTAL')}</RankingSpan>, car.rank.total]);
    if (car.stat) {
      addSpacer(result);
      result.push([t('STOCK'), car.stat.stock_count]);
      result.push([t('SOLD'), car.stat.sold_count]);
      addSpacer(result);

      result.push([t('STAND_TIME_AVERAGE_IN_STOCK'), car.stock_days_average_in_stock]);
      result.push([t('STAND_TIME_AVERAGE'), car.stock_days_average]);
    }
    if (car.stat) {
      let colorval = '';
      let colorStyle = '';
      if (car.stat.price_warning === 1) {
        colorval = '0.5k';
        colorStyle = styles.orange;
      } else if (car.stat.price_warning === 2) {
        colorval = '1k';
        colorStyle = styles.red;
      } else if (car.stat.price_warning === 3) {
        colorval = '2k';
        colorStyle = styles.black;
      }
      if (colorval !== '') {
        addSpacer(result);
        result.push([
          t('AVERAGE_DISCOUNT'),
          <DivRelativePosition>
            <span>{colorval}</span>
            <DivRelativePosition>
              <ColorSpan className={colorStyle} />
            </DivRelativePosition>
          </DivRelativePosition>,
        ]);
      }
    }
    if (car.price?.history?.length) {
      const latestPrice = car.price.history[car.price.history.length - 1];

      if (!car.stat) {
        addSpacer(result);
      }

      result.push([
        t('LAST_PRICE_ADJUSTMENT'),
        <>
          <div>&euro;&nbsp;{formatThousands(latestPrice.value)}</div>
          <div>{latestPrice.label}</div>
        </>,
      ]);
    }

    if (user.permissions.includes('showPurchasePrice') && car.price?.purchase) {
      addSpacer(result);
      result.push([t('PURCHASE_PRICE'), <>&euro;&nbsp;{formatThousands(car.price?.purchase)}</>]);
    }

    if (car.trace?.rank) {
      result.push([
        <>
          <hr style={{ borderTop: 'dashed 1px' }} />
          <table style={{ fontWeight: '400' }}>
            <tbody>
              <tr>
                <td style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{t('TARGET_POSITION')}</td>
                <td></td>
                <td></td>
              </tr>
              {car.trace.rank
                .flatMap((r) => r.rank_trace)
                .map((row, index) => (
                  <tr key={index}>
                    <td>{capitalizeFirstLetter(t(row.rule.toUpperCase()))}</td>
                    <td style={{ whiteSpace: 'nowrap', minWidth: '50px' }} align={'center'}>
                      {row.value}
                    </td>
                    <td style={{ minWidth: '50px' }} align={'center'}>
                      {row.percent && Math.round(row.percent * 10000) / 100 + '%'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <hr style={{ borderTop: 'dashed 1px' }} />
        </>,
        <>
          <hr style={{ borderTop: 'dashed 1px' }} />
          <table>
            <tbody>
              <tr>
                <td>&nbsp;</td>
              </tr>
              {car.trace.rank
                .flatMap((r) => r.rank_trace)
                .map((row, index) => (
                  <tr key={index}>
                    <td style={{ paddingLeft: '-10px' }}>{row.position}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <hr style={{ borderTop: 'dashed 1px', borderRight: 'dashed 1px' }} />
        </>,
      ]);
    }
  }

  return result;
};
