import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTable, TableColDef, Tooltip } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { CarWindowColumn, CarWindowColumns } from './car-window-columns';
import { ClicksPassedDown, useClickQueryStates } from '../../states/click-states';
import { CarWindowTableCells } from './car-window-table-cells';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PublicIcon from '@mui/icons-material/Public';
import { CarWindowMap } from './map/car-window-map';

interface CarWindowTableProps {
  cars: CarResult[];
  ownCar: CarResult;
  clicks: ClicksPassedDown;
  reorder: (source: number, destination: number) => void;
  removeRanking: () => void;
  taxationView: boolean;
  error?: string;
}

export const CarWindowTable = ({
  cars,
  ownCar,
  clicks,
  reorder,
  removeRanking,
  taxationView,
  error,
}: CarWindowTableProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const BannedCarIcon = () => {
    const clickQueryStates = useClickQueryStates();
    return (
      <>
        {clickQueryStates.clickQueryState.clickIncludeBanned ? (
          <VisibilityIcon onClick={() => clickQueryStates.clickQuerySetters.setClickIncludeBanned(false)} />
        ) : (
          <VisibilityOffIcon onClick={() => clickQueryStates.clickQuerySetters.setClickIncludeBanned(true)} />
        )}
      </>
    );
  };

  const mapCarWindowColumn = ({
    headerName = '',
    flex = 1,
    columnType,
    clickName,
    headerType = 'basic',
    headerAlign = 'center',
    field,
    headerDescription = '',
    ...rest
  }: CarWindowColumn): TableColDef<CarResult> => ({
    ...rest,
    flex: flex,
    headerName: t(headerName),
    headerDescription: headerDescription,
    sortable: false,
    headerAlign: headerAlign,
    field,
    renderHeader: () => {
      const unlockMapIconActive = clicks.location.value !== undefined || clicks.polygon.value !== undefined;

      const onClick = () => {
        const value = clicks.polygon.value || clicks.location.value;
        clicks.polygon.setter(!value);
        clicks.location.setter(!value);
      };

      const icon =
        clicks.location.value || clicks.polygon.value ? (
          <VpnLockIcon onClick={onClick} />
        ) : (
          <PublicIcon onClick={onClick} />
        );

      return (
        <>
          {ownCar.includes_banned && headerType === 'trash' && <BannedCarIcon />}
          {headerType === 'basic' && (
            <Tooltip onHoverContent={headerDescription ? t(headerDescription) : t(headerName)}>{t(headerName)}</Tooltip>
          )}
          {ownCar.latitude &&
            ownCar.longitude &&
            user?.permissions.includes('carWindowMap') &&
            field === 'equipment' && (
              <>
                <CarWindowMap ownCar={ownCar} windowCarIds={ownCar.window_car_ids} />
                {unlockMapIconActive && icon}
              </>
            )}
        </>
      );
    },
    renderCell: (params) => (
      <CarWindowTableCells
        car={params.row}
        columnType={columnType}
        ownCar={ownCar}
        taxationView={taxationView}
        removeRanking={removeRanking}
        clickName={clickName}
        clicks={clicks}
        value={params.value}
        ownDealer={params.row.dealer?.name === user?.dealer}
      />
    ),
  });

  const columns: TableColDef<CarResult>[] = [...CarWindowColumns.map(mapCarWindowColumn)];

  return (
    <BaseTable
      rows={cars}
      columns={columns}
      getRowId={(row) => row.id}
      headerHeight={25}
      rowHeight={68}
      exportToPdf={false}
      useStripedRows={false}
      getRowClassName={(params) => {
        const carType = params.id === ownCar.id ? 'ownCar' : 'dealerCars';
        const bannedCar = params.is_banned ? 'bannedCar' : '';
        return `${carType} ${bannedCar}`;
      }}
      reorderModel={{
        reorder: reorder,
        reorderColumn: {
          width: 10,
          minWidth: 20,
          cellClassName: (params) => (params.id === ownCar.id ? 'showIcon' : 'hideIcon'),
        },
      }}
      error={error}
      disableStickyHeader
      sx={(theme) => ({
        '.bannedCar, .bannedCar:hover': {
          backgroundColor: theme.palette.warning.main,
        },
        '.ownCar, .ownCar:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '.noPadding': {
          padding: 0,
        },
        '.hideIcon > div': {
          display: 'none',
        },
      })}
    />
  );
};
