import React from 'react';
import { Link, SvgIcon } from '@mui/material';
import { styled } from '@mui/system';
import { CarWindowImageCell } from './cells/car-window-image-cell';
import { CarWindowDealerImageCell } from './cells/car-window-dealer-image-cell';
import { CarWindowDeltaCell } from './cells/car-window-delta-cell';
import { ClickLink } from './cells/car-window-click-link';
import { CarWindowEquipmentCell } from './cells/car-window-equipment/car-window-equipment-cell';
import { CarWindowPriceCell } from './cells/car-window-price-cell';
import { CarWindowBanCell } from './cells/car-window-ban-cell';
import { formatThousands } from '../../utils/number-format';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ClicksPassedDown } from '../../states/click-states';
import { ReactComponent as Redirect } from '../../../public/images/icons_svg/redirect.svg';
import { CarWindowOptionsCell } from './cells/car-window-options-cell/car-window-options-cell';

const FlagImage = styled('img')({
  cursor: 'pointer',
  width: '17px',
  transform: 'scale(1)',
  transition: 'all 0.15s',
});

interface CarWindowTableCellsProps {
  car: CarResult;
  ownCar: CarResult;
  clicks: ClicksPassedDown;
  removeRanking: () => void;
  columnType: string;
  clickName?: string;
  value: any;
  taxationView: boolean;
  ownDealer: boolean;
}

export const CarWindowTableCells = ({
  car,
  ownCar,
  clicks,
  removeRanking,
  columnType,
  clickName,
  value,
  taxationView,
  ownDealer,
}: CarWindowTableCellsProps) => (
  <>
    {columnType === 'image' && <CarWindowImageCell ownCar={ownCar} car={car} taxationView={taxationView} />}
    {columnType == 'price' && <CarWindowPriceCell car={car} removeRanking={removeRanking} />}
    {columnType == 'logo' && (
      <CarWindowDealerImageCell
        car={car}
        ownDealer={ownDealer}
        setOwnSupply={clicks.clickOwnSupply.setter}
        ownSupply={clicks.clickOwnSupply.value}
      />
    )}
    {columnType == 'delta' && <CarWindowDeltaCell car={car} />}
    {columnType === 'click' && clickName && (
      <ClickLink setValue={clicks[clickName].setter} value={clicks[clickName].value}>
        <span>{value}</span>
      </ClickLink>
    )}
    {columnType === 'mileage' && <>{formatThousands(value)}</>}
    {columnType === 'equipment' && (
      <CarWindowEquipmentCell car={car} clicks={clicks} isOfficialMake={!!ownCar.equipment?.official_make} />
    )}
    {columnType === 'options' && <CarWindowOptionsCell car={car} order={ownCar.options_order} clicks={clicks} />}
    {columnType === 'redirect' && (!taxationView || ownCar.id !== car.id) && (
      <Link href={car.url} target="_blank">
        <SvgIcon component={Redirect} color="primary" viewBox="0 0 24 15" sx={{ fontSize: 20 }} />
      </Link>
    )}
    {columnType === 'flag' && car.stat?.local_car && (
      <FlagImage
        src="/images/flags/NL.gif"
        title="niet-import"
        onClick={() => clicks.clickStatLocalCar.setter(!clicks.clickStatLocalCar.value)}
      />
    )}
    {columnType === 'trash' && (
      <CarWindowBanCell car={car} ownCar={ownCar} ownDealer={ownDealer} taxationView={taxationView} />
    )}
    {columnType === 'basic' && <>{value}</>}
  </>
);
