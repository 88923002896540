import { Grid2 } from '@mui/material';
import React from 'react';
import { useAppSearchParams } from '@japieglobal/shared';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { DisplayHints } from '@japieglobal/shared/src/components/hints/display-hints';
import { TaxationTopFilters } from './top-side-filters/taxation-top-filter';
import { ShoppingCartButton } from '../../taxation-and-results-shared/buttons/shopping-cart-button';
import { MailButton } from '../../taxation-and-results-shared/buttons/mail-button/mail-button';
import { ResultTableTrace } from '../../taxation-and-results-shared/buttons/result-table-trace-button';
import { SaveTaxation } from '../../saved-taxations/save-taxation';
import { OwnStockCount } from '../../taxation-and-results-shared/own-stock-count/own-stock-count';
import { RedirectPurchaseButton } from '../../taxation-and-results-shared/buttons/redirect-purchase-button';

interface TaxationCarViewProps {
  car: CarResult | undefined;
}
export const TaxationCarView = ({ car }: TaxationCarViewProps) => {
  const [searchParams] = useAppSearchParams();
  const { url } = searchParams;
  return (
    <Grid2 container spacing={2}>
      <TaxationTopFilters />
      {car && (
        <Grid2 container alignItems={'center'} columnSpacing={2} size={4}>
          {url !== undefined && (
            <Grid2>
              <RedirectPurchaseButton url={url} />
            </Grid2>
          )}
          <Grid2>
            <ShoppingCartButton car={car} />
          </Grid2>
          <Grid2>
            <SaveTaxation car={car} />
          </Grid2>
          <Grid2>
            <MailButton isTaxation car={car} />
          </Grid2>
          <Grid2>
            <ResultTableTrace car={car} />
          </Grid2>
          {car.stat?.own_supply_window_count !== undefined && car.stat.own_supply_window_count > 1 && (
            <Grid2>
              <OwnStockCount car={car} />
            </Grid2>
          )}
        </Grid2>
      )}
      <DisplayHints />
    </Grid2>
  );
};
