import React, { useCallback, useEffect } from 'react';
import { NumberAppSearchParam } from '../../types';
import { BaseInputFilterProps } from '../../types/base-filter-props';
import { useTranslation } from 'react-i18next';
import { InputWrapper } from '../../wrapper/filter-input-wrapper/filter-input-wrapper';
import { useAppSearchParams } from '../../hooks';
import { NumberInput } from '../../../inputs';

export interface NumberFilterProps extends BaseInputFilterProps<number | undefined, NumberAppSearchParam> {
  min?: number;
  max?: number;
  width?: 'fill' | number;
  autoFocus?: boolean;
  className?: string;
  endAdornment?: string;
}

export const NumberFilter = ({
  searchParamKey,
  labelTranslationKey,
  defaultValue,
  max,
  min,
  autoFocus,
  width,
  hideResetButton,
  className,
  hasBackendDefault,
  tabOnEnter,
  endAdornment,
}: NumberFilterProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();
  const { t } = useTranslation();

  const value = searchParams[searchParamKey];

  const onHandleReset = () => {
    searchParams[searchParamKey] = undefined;
    setSearchParams(searchParams);
  };

  const onHandleChange = useCallback(
    (value: number | undefined) => {
      searchParams[searchParamKey] = value;
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams, searchParamKey],
  );

  useEffect(() => {
    if (!value && !hasBackendDefault) {
      onHandleChange(defaultValue);
    }
  }, [onHandleChange, value, defaultValue, hasBackendDefault]);

  return (
    <InputWrapper hideResetButton={hideResetButton} onReset={onHandleReset}>
      <NumberInput
        tabOnEnter={tabOnEnter}
        label={t(labelTranslationKey)}
        width={width}
        min={min}
        max={max}
        autoFocus={autoFocus}
        setValue={onHandleChange}
        value={value}
        defaultValue={defaultValue}
        className={className}
        endAdornment={endAdornment}
      />
    </InputWrapper>
  );
};
