import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogContent, IconButton, SvgIcon, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColoredButton, LoadingWrapper, Tooltip } from '@japieglobal/shared';
import { ChatMessageBubble } from './chat-message-bubble';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import {
  createCsmDealerMessage,
  deleteCsmDealerMessage,
  editCsmDealerMessage,
  getCsmDealerMessages,
  Message,
} from '@japieglobal/shared/src/api/services';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { CommentBoxForm, CommentBoxText, StyledDialogTitle, useStyles } from './styled';
import MessageIcon from '@mui/icons-material/Message';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import moment from 'moment';
import { ReactComponent as JiraIcon } from '../../../../../../public/images/icons_svg/jira.svg';

interface ActionsCellProps {
  dealerAccountName: string;
  messages?: Message[];
}

export const MessagesCell = ({ messages: initialMessages, dealerAccountName }: ActionsCellProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const [showDialog, setShowDialog] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [commentListContainer, setCommentListContainer] = useState<HTMLDivElement | undefined>(undefined);
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [sendToJira, setSendToJira] = useState<boolean>(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [appointmentDateTime, setAppointmentDateTime] = useState<moment.Moment | null>(null);

  const scrollToBottom = useCallback(() => {
    if (commentListContainer) {
      commentListContainer.scrollTop = commentListContainer.scrollHeight;
    }
  }, [commentListContainer]);

  const postComment = () => {
    if (newComment) {
      createCsmDealerMessage({
        account_name: dealerAccountName,
        message: newComment,
        send_to_jira: sendToJira,
        ics_datetime: moment(appointmentDateTime).toISOString(),
      })
        .then(() => {
          setNewComment('');
          snackbarSuccessMessage('Comment has been posted 👍');
          let url = window.location.href;
          if (!url.includes('dealer=')) {
            url = url + `&dealer=${dealerAccountName}`;
          }
          setSendToJira(false);
          getMessages();
        })
        .catch(snackbarErrorHandler);
    }
  };

  const deleteComment = (commentId: number) => {
    if (commentId) {
      deleteCsmDealerMessage(commentId)
        .then(() => {
          setNewComment('');
          snackbarSuccessMessage('Comment has been deleted 🗑');
          getMessages();
        })
        .catch(snackbarErrorHandler);
    }
  };

  const editComment = (messageId: number, message: string) => {
    if (message && messageId) {
      editCsmDealerMessage(messageId, {
        account_name: dealerAccountName,
        message: newComment,
        send_to_jira: sendToJira,
        ics_datetime: moment(appointmentDateTime).toString(),
      })
        .then(() => {
          setNewComment('');
          snackbarSuccessMessage('Comment has been edited 👍');
          getMessages();
        })
        .catch(snackbarErrorHandler);
    }
  };

  const getMessages = useCallback(() => {
    setIsLoadingMessages(true);
    getCsmDealerMessages(dealerAccountName)
      .then(setMessages)
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoadingMessages(false));
  }, [dealerAccountName, snackbarErrorHandler]);

  useEffect(() => {
    if (showDialog === true) {
      getMessages();
    }
  }, [getMessages, showDialog]);

  useEffect(() => {
    if (commentListContainer && showDialog === true && messages && messages.length > 0) {
      scrollToBottom();
    }
  }, [commentListContainer, scrollToBottom, showDialog, messages]);

  return (
    <>
      {messages && messages?.length > 0 ? (
        <Tooltip
          onHoverContent={
            <div style={{ width: 700 }}>
              <ChatMessageBubble messages={[messages[messages?.length - 1]]} />
            </div>
          }
        >
          <IconButton onClick={() => setShowDialog(true)} color="primary" aria-label="open-messages">
            <MessageIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip onHoverContent={'No Messages'}>
          <IconButton onClick={() => setShowDialog(true)} color="primary" aria-label="open-messages">
            <MessageIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        fullWidth
        onClose={() => setShowDialog(false)}
        aria-labelledby="export"
        open={showDialog}
        disableEnforceFocus
        classes={{ paper: classes.dialogPaper }}
      >
        <StyledDialogTitle>
          <div>{dealerAccountName}</div>
        </StyledDialogTitle>
        <DialogContent ref={setCommentListContainer} dividers style={{ minHeight: 'calc(80% - 64px)' }}>
          <LoadingWrapper isLoading={isLoadingMessages}>
            <ChatMessageBubble messages={messages} onDelete={deleteComment} onEdit={editComment} />
          </LoadingWrapper>
        </DialogContent>
        <CommentBoxForm dividers>
          <CommentBoxText
            value={newComment}
            onChange={(event) => setNewComment(event.target.value)}
            type="text"
            placeholder={t('WRITE_YOUR_MESSAGE_HERE')}
            onKeyPress={(event) => (event.key === 'Enter' ? postComment() : undefined)}
          />
          <IconButton sx={{ opacity: sendToJira ? 1 : 0.2 }} onClick={() => setSendToJira(!sendToJira)}>
            <Tooltip onHoverContent={t('CREATE_JIRA_TICKET')}>
              <SvgIcon inheritViewBox component={JiraIcon} />
            </Tooltip>
          </IconButton>
          <Tooltip onHoverContent={t('CREATE_AGENDA_ITEM')}>
            <DateTimePicker
              slotProps={{
                actionBar: {
                  actions: ['clear', 'accept'],
                },
              }}
              onAccept={() => setOpenDatePicker(false)}
              onClose={() => setOpenDatePicker(false)}
              sx={{ visibility: 'hidden', width: 0 }}
              minDate={moment()}
              open={openDatePicker}
              ampm={false}
              onChange={setAppointmentDateTime}
              value={appointmentDateTime}
            />
          </Tooltip>
          <IconButton onClick={() => setOpenDatePicker(!openDatePicker)}>
            {appointmentDateTime ? <EventAvailableIcon /> : <InsertInvitationIcon />}
          </IconButton>
          <ColoredButton type="submit" onClick={postComment}>
            {t('SEND')}
          </ColoredButton>
        </CommentBoxForm>
      </Dialog>
    </>
  );
};
