import { DirectionsCar, ListAlt, Receipt, ShoppingCart } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import GarageIcon from '@mui/icons-material/Garage';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import GroupIcon from '@mui/icons-material/Group';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { Button, Grid2, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageContainer } from '@japieglobal/shared/src/components';
import GavelIcon from '@mui/icons-material/Gavel';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { UserRoles } from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';

const useStyles = makeStyles(() => ({
  menuButton: {
    'display': 'flex',
    'flexDirection': 'column',
    'textAlign': 'center',
    '& svg': {
      fontSize: 40,
    },
  },
}));

const AdminMenuView = React.memo(() => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isSuperAdmin = user.role === UserRoles.SUPER_ADMIN;
  const isAdmin = isSuperAdmin || user.role === UserRoles.ADMIN;
  const styles = useStyles();
  const purchasePermission = user.permissions.includes('purchase');
  const protocolPermission = user.permissions.includes('protocol');
  const aprPermission = user.permissions.includes('apr');
  const buildWindowsPermission = protocolPermission;
  const dealerPermission = protocolPermission;

  return (
    <Paper>
      <Grid2 container spacing={2} mt={5} p={5}>
        {isAdmin && (
          <Grid2 size={2}>
            <Button href={`/admin/users?userFilter%5Bdealer%5D=${user.dealer}`} className={styles.menuButton}>
              <GroupIcon />
              <span>{t('USER_MANAGEMENT')}</span>
            </Button>
          </Grid2>
        )}
        {aprPermission && (
          <Grid2 size={2}>
            <Button href="/admin-api/APR" className={styles.menuButton}>
              <PrecisionManufacturingIcon />
              <span>{t('APR')}</span>
            </Button>
          </Grid2>
        )}
        {protocolPermission && (
          <Grid2 size={2}>
            <Button href="/admin-api/protocols" className={styles.menuButton}>
              <ListAlt />
              <span>{t('PROTOCOLS')}</span>
            </Button>
          </Grid2>
        )}
        {purchasePermission && (
          <Grid2 size={2}>
            <Button href="/admin-api/purchase" className={styles.menuButton}>
              <ShoppingCart />
              <span>{t('PURCHASE')}</span>
            </Button>
          </Grid2>
        )}
        {dealerPermission && (
          <Grid2 size={2}>
            <Button href="/admin-api/dealer" className={styles.menuButton}>
              <DirectionsCar />
              <span>{t('MY_DEALER_SETTINGS')}</span>
            </Button>
          </Grid2>
        )}
        {isSuperAdmin && (
          <Grid2 size={2}>
            <Button href="/admin-api/request_logs" className={styles.menuButton}>
              <Receipt />
              <span>{t('REQUEST_LOGS')}</span>
            </Button>
          </Grid2>
        )}
        {isSuperAdmin && (
          <Grid2 size={2}>
            <Button href="/admin-api/dealers" className={styles.menuButton}>
              <GarageIcon />
              <span>{t('DEALER_OVERVIEW')}</span>
            </Button>
          </Grid2>
        )}
        {buildWindowsPermission && (
          <Grid2 size={2}>
            <Button href="/admin-api/windows" className={styles.menuButton}>
              <BuildIcon />
              <span>{t('BUILD_WINDOWS')}</span>
            </Button>
          </Grid2>
        )}
        {isSuperAdmin && (
          <>
            {user.permissions.includes('scrapeManagement') && (
              <Grid2 size={2}>
                <Button href="/admin-scrape" className={styles.menuButton}>
                  <LibraryBooksIcon />
                  <span>{t('SCRAPE_MANAGEMENT')}</span>
                </Button>
              </Grid2>
            )}
            <Grid2 size={2}>
              <Button href="/admin-api/locations" className={styles.menuButton}>
                <LocationCityIcon />
                <span>{t('LOCATION_MANAGEMENT')}</span>
              </Button>
            </Grid2>
            <Grid2 size={2}>
              <Button href="/purchase/auctions" className={styles.menuButton}>
                <GavelIcon />
                <span>JP.auctions</span>
              </Button>
            </Grid2>
            <Grid2 size={2}>
              <Button href="/super-admin" className={styles.menuButton}>
                <LocalPoliceIcon />
                <span>SUPER ADMIN</span>
              </Button>
            </Grid2>
          </>
        )}
      </Grid2>
    </Paper>
  );
});

export function AdminMenuPage() {
  const mainComponent = useMemo(() => <AdminMenuView />, []);

  return <PageContainer internal hideResetAllButton mainComponent={mainComponent} />;
}
