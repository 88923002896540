import React from 'react';
import { RangeFilter, AppSearchParamKeys } from '@japieglobal/shared/src/components';

export const FilterWindowSize = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.WINDOW_SIZE_FROM, AppSearchParamKeys.WINDOW_SIZE_TO]}
    defaultValue={[0, 1000]}
    min={0}
    max={1000}
    labelTranslationKey="WINDOW_SIZE"
  />
);
