import { PaletteOptions } from '@mui/material/styles';

export enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ThemeColors {
  PRIMARY: string;
  PRIMARY_LIGHT: string;
  PRIMARY_TEXT: string;
  SECONDARY: string;
  SECONDARY_DARK: string;
  SECONDARY_TEXT: string;
  WARNING: string;
  WARNING_TEXT: string;
  INFO_LIGHT: string;
  INFO_DARK: string;
  INFO: string;
  INFO_TEXT: string;
  BACKGROUND: string;
}
export interface ThemeProps {
  palette: PaletteOptions;
  colors: ThemeColors;
  darkImageFilter: string | undefined;
  lightImageFilter: string | undefined;
  greyImageFilter: string | undefined;
}

export interface ImageFilterProps {
  darkFilter?: string;
  lightFilter?: string;
  greyFilter?: string;
}
