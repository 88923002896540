import React from 'react';
import { AppSearchParamKeys, NumberFilter } from '@japieglobal/shared/src';
import { settings } from '@japieglobal/shared/src/settings';

export const TaxationFilterExtraCosts = () => {
  return (
    <NumberFilter
      min={0}
      max={1000000}
      labelTranslationKey="EXTRA_COSTS"
      searchParamKey={AppSearchParamKeys.EXTRA_COSTS}
      endAdornment={settings().currencySymbol}
      hasBackendDefault
    />
  );
};
