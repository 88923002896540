import { ColoredButton, Tooltip } from '@japieglobal/shared/src/components';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Dialog, DialogActions, DialogContent, useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { TaxationsTable } from './taxations-table';
import {
  getSavedTaxation,
  SavedTaxation,
  SavedTaxationQuery,
  saveTaxation,
} from '@japieglobal/shared/src/api/services';
import { CarResult } from '@japieglobal/shared/src/types';
import { ColoredButtonLargeText } from '../cars/results/result-table-styles';
import { useSearchParams } from 'react-router-dom';
import { settings } from '@japieglobal/shared/src/settings';

const IconButton = styled(ColoredButtonLargeText)({
  width: '35px',
  height: '30px',
  fontSize: '15px',
});

const useStyles = makeStyles({
  general: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '6px',
    marginBottom: '16px',
    resize: 'vertical',
  },
  rounded: {
    padding: '12px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
});

interface SaveTaxationDialogProps {
  car: CarResult;
  showDialog: boolean;
  setShowDialog: (val: boolean) => void;
  setTaxations: any;
}
const SaveTaxationDialog = ({ car, showDialog, setShowDialog, setTaxations }: SaveTaxationDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [description, setDescription] = useState<string | undefined>();
  const classes = useStyles();
  const handleClose = useCallback(() => setShowDialog(false), [setShowDialog]);
  const [searchParams] = useSearchParams();

  const onSubmit = useCallback(() => {
    saveTaxation(
      car,
      description,
      searchParams.get('click_options'),
      searchParams.get('click_equipment_in_stock'),
      searchParams.get('extra_costs'),
      searchParams.get('extra_margin'),
    )
      .then((res) => {
        setTaxations((taxations: SavedTaxation[]) => [res].concat(taxations));
      })
      .finally(() => handleClose());
  }, [car, description, searchParams, setTaxations, handleClose]);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="export"
      open={showDialog}
      disableEnforceFocus
    >
      <DialogTitle>{t('SAVE_TAXATION')}</DialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img height="80px" style={{ marginRight: '10px' }} alt="car" src={car.image} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h4>
              {car.make}&nbsp;{car.model}&nbsp;{settings().currencySymbol}
              {car.rank?.target_value}
              <div>{car.license_plate}</div>
            </h4>
            <span>
              {t('VVP_GROSS')}: {settings().currencySymbol}
              {car.rank?.target_value}
            </span>
            {car.topdown_value && (
              <span>
                {t('TDC_GROSS')}: {settings().currencySymbol}
                {car.topdown_value}
              </span>
            )}
            {!!Number(searchParams.get('extra_costs')) && (
              <span>
                {t('EXTRA_COSTS')}: {settings().currencySymbol}
                {searchParams.get('extra_costs')}
              </span>
            )}
            {!!Number(searchParams.get('extra_margin')) && (
              <span>
                {t('EXTRA_MARGIN')}: {searchParams.get('extra_margin')}%
              </span>
            )}
          </div>
          <div style={{ flexGrow: 1 }} />
        </div>
        <form onSubmit={onSubmit} style={{ padding: '5px' }}>
          <span>{t('NOTE')}</span>
          <textarea
            className={`${classes.rounded} ${classes.general}`}
            rows={5}
            placeholder={t('OPTIONAL')}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <ColoredButton onClick={onSubmit}>{t('SEND')}</ColoredButton>
      </DialogActions>
    </Dialog>
  );
};

export const SaveTaxation = React.memo(({ car }: { car: CarResult }) => {
  const [taxations, setTaxations] = useState<SavedTaxation[] | undefined>();
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!car?.dealer?.name) return;
    const params: SavedTaxationQuery = {
      license_plate: car.license_plate,
      make: car.make,
      model: car.model,
      fuel: car.fuel,
      gear: car.gear,
      body: car.body,
      build: car.build,
      mileage: car.mileage,
    };
    getSavedTaxation(car.dealer.name, params).then((res) => {
      setTaxations(res.results);
    });
  }, [car.dealer, car.license_plate, car.make, car.model, car.fuel, car.gear, car.body, car.build, car.mileage]);

  return (
    <>
      {!!taxations?.length && (
        <Tooltip
          onHoverContent={<TaxationsTable taxations={taxations} hoverTable />}
          placement="bottom-end"
          interactive
        >
          <IconButton style={{ color: 'white', backgroundColor: 'red' }}>
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
      )}
      <IconButton onClick={() => setShowDialog(true)} title={t('SAVE_TAXATION')} className="saveTaxationButton">
        <CloudUploadOutlinedIcon />
      </IconButton>
      {showDialog && (
        <SaveTaxationDialog
          car={car}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          setTaxations={setTaxations}
        />
      )}
    </>
  );
});
