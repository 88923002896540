import React from 'react';
import { AppSearchParamKeys, RangeFilter } from '@japieglobal/shared/src/components';

export const FilterPrice = () => (
  <RangeFilter
    searchParamKey={[AppSearchParamKeys.PRICE_FROM, AppSearchParamKeys.PRICE_TO]}
    defaultValue={[0, 5000000]}
    min={0}
    labelTranslationKey="PRICE"
  />
);
