import { Box, Grid2, Stack, Tab, Tabs } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppSearchParamKeys, ProgressIndicator, Tooltip } from '@japieglobal/shared/src/components';
import { formatThousands } from '../../../utils/number-format';
import { TopdownHover } from './topdown-hover';
import { CarWindowResult } from '@japieglobal/shared/src/api/services';
import { ResultTurnoverButton } from '../../../taxation-and-results-shared/buttons/result-turnover-button';
import { ResultTableAdditionalInformation } from '../../../taxation-and-results-shared/result-table-additional-information-cell/result-table-additional-information-cell';
import { ResultTaxesCell } from '../../../taxation-and-results-shared/result-taxes-cell/result-taxes-cell';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { settings } from '@japieglobal/shared/src/settings';

interface ResultCountryProps {
  country: string;
  windowResult?: CarWindowResult;
  isLoading: boolean;
}

const ResultCountry = ({ isLoading, country, windowResult }: ResultCountryProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { car } = windowResult || {};

  return (
    <>
      {isLoading && <ProgressIndicator />}
      <Stack direction="row" spacing={2}>
        <div>
          <img
            width={30}
            src={`/images/flags/${country.toUpperCase()}.png`}
            style={{ marginTop: 10 }}
            title={country.toUpperCase()}
          />
        </div>
        <div>
          {!!car?.rank?.target_value && user.permissions.some((p) => p.startsWith('internationalTrader')) && (
            <Tooltip onHoverContent={settings().country === 'nl' ? t('SELLING_PRICE_NET_DESCRIPTION') : ''}>
              <Box sx={{ textAlign: 'left' }} className="vvpResult">
                <span>
                  {t('VVP_NET')}: {settings().currencySymbol} {formatThousands(car.rank?.target_value_exex)}
                </span>
              </Box>
            </Tooltip>
          )}
          {!!car?.topdown_value && user.permissions.includes('showTopDownValue') && (
            <>
              {car?.rank?.target_value_exex &&
                car?.rank?.target_value &&
                user.permissions.some((p) => p.startsWith('internationalTrader')) && (
                  <Box sx={{ textAlign: 'left' }} mb={0.5} mt={0.5} className="tdcNetResult">
                    <TopdownHover
                      value_label={t('VVP_NET')}
                      topdown_value_label={t('TDC_NET')}
                      topdown_value={car?.topdown_value_exex}
                      value={car?.rank?.target_value_exex}
                      topdown_value_breakdown={car?.topdown_value_exex_breakdown}
                    />
                  </Box>
                )}
              <Box sx={{ textAlign: 'left' }} mb={0.5} className="tdcGrossResult">
                <TopdownHover
                  value_label={t('VVP_GROSS')}
                  topdown_value_label={t('TDC_GROSS')}
                  topdown_value={car?.topdown_value}
                  value={car?.rank?.target_value}
                  topdown_value_breakdown={car?.topdown_value_breakdown}
                />
              </Box>
            </>
          )}
          {!!car?.rank?.target_value_at_maturity && (
            <Box sx={{ textAlign: 'left' }} mb={0.5}>
              <Tooltip onHoverContent={t('MATURITYVALUE_TITLE')}>
                <div>
                  {t('SHOWMATURITYVALUE')}: {settings().currencySymbol}{' '}
                  {formatThousands(car.rank?.target_value_at_maturity)}&nbsp;
                </div>
              </Tooltip>
              {!!car.topdown_value_at_maturity && user.permissions.includes('showTopDownValue') && (
                <TopdownHover
                  table_header={t('FUTURE_TOPDOWNVALUE_TITLE')}
                  value_label={t('SHOWMATURITYVALUE')}
                  topdown_value_label={t('FUTURE_TOPDOWNVALUE')}
                  topdown_value={car.topdown_value_at_maturity}
                  value={car.rank?.target_value_at_maturity}
                  topdown_value_breakdown={car.topdown_value_at_maturity_breakdown}
                />
              )}
            </Box>
          )}
          {!!car?.rank?.target_value && (
            <div>
              <ResultTurnoverButton car={car} searchParamKey={AppSearchParamKeys.SORT_TURNOVER_INT} column="itr" />
              <ResultTurnoverButton car={car} searchParamKey={AppSearchParamKeys.SORT_TURNOVER_EXT} column="etr" />
              {car?.apr != null && (
                <ResultTurnoverButton car={car} searchParamKey={AppSearchParamKeys.SORT_APR} column="apr" />
              )}
            </div>
          )}
        </div>
        {!!car?.rank?.target_value ? (
          <div>
            <Box>
              <ResultTableAdditionalInformation car={car} />
            </Box>
            <ResultTaxesCell car={car} />
          </div>
        ) : (
          <Box sx={{ color: '#656565' }}>{t('NOT_AVAILABLE')}</Box>
        )}
      </Stack>
    </>
  );
};

interface ResultOtherCountriesProps {
  windowResults: { [country: string]: CarWindowResult | undefined };
  windowIsLoading: { [country: string]: boolean };
  setSelectedCountry: (country: string) => void;
}

export const ResultOtherCountries = ({
  windowIsLoading,
  windowResults,
  setSelectedCountry,
}: ResultOtherCountriesProps) => {
  const keys = Object.keys(windowResults);
  const [value, setValue] = useState<number>(0);

  const handleChange = (event, newValue: number) => {
    setValue(newValue);
    setSelectedCountry(keys[newValue]);
  };

  return (
    <Grid2 container>
      <Tabs
        variant={keys.length >= 4 ? 'scrollable' : 'standard'}
        style={{ marginLeft: keys.length >= 4 ? '-40px' : '0px' }}
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ style: { height: '10px', fontSize: '100px' } }}
      >
        {keys.map((country) => (
          <Tab
            key={country}
            component="div"
            label={
              <ResultCountry
                isLoading={windowIsLoading[country]}
                country={country}
                windowResult={windowResults[country]}
              />
            }
            sx={{
              fontSize: 13,
              textTransform: 'none',
              minWidth: '26%',
              height: '100%',
              border: '1px groove',
            }}
          />
        ))}
      </Tabs>
    </Grid2>
  );
};
