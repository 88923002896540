import React from 'react';
import { isMobile } from '../utils/is-mobile';
import { FilterMoreButton } from './filter-more-button';
import { FilterStockDays } from './filter-stock-days';
import { FilterSearch } from './filter-search';
import { FilterThumbsUpDown } from './click-filters/filter-thumbs-up-down';
import { FilterLocation } from './filter-location';
import { FilterSupplyType } from './filter-supply-type';
import { FilterStatus } from './filter-status';
import { DisplayHints } from '../../../shared/src/components/hints/display-hints';
import { FilterExtraFilters } from './filter-extra-filters';
import { Box } from '@mui/material';
import { AppSearchParamKeys, useFilterGroup } from '@japieglobal/shared/src';
import { MissingOptionsButton } from './click-filters/missing-options-button';

export const Filter = () => {
  useFilterGroup([
    AppSearchParamKeys.THREE_THUMBS_UP,
    AppSearchParamKeys.THREE_THUMBS_DOWN,
    AppSearchParamKeys.SHOW_MESSAGES_CARS,
    AppSearchParamKeys.SHOW_PRICE_PROPOSAL_CARS,
    AppSearchParamKeys.SHOW_HIDDEN_CARS,
    AppSearchParamKeys.THREE_THUMBS_DOWN,
  ]);
  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
      <FilterLocation />
      <FilterStatus />
      <FilterSupplyType />
      <FilterSearch />
      <FilterThumbsUpDown />
      <FilterStockDays />
      <MissingOptionsButton />
      <FilterExtraFilters />
      {!isMobile.any() && <FilterMoreButton isDemo={false} />}
      <DisplayHints />
    </Box>
  );
};
