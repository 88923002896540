import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getPermissions, Permission, PermissionCategory, User, UserRoles } from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';

interface AdminUserPermissionsProps {
  permissions: string[];
  setPermissions: (val: string[]) => void;
  isScrape?: boolean;
  editUser: Partial<User>;
}

export const AdminUserPermissions = ({
  setPermissions,
  permissions,
  isScrape,
  editUser,
}: AdminUserPermissionsProps) => {
  const [allowedPermissions, setAllowedPermissions] = useState<Permission[]>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getPermissions().then((res) => {
      const filteredPermissions = res
        .filter((p) => user.permissions.includes(p.name))
        .filter((p) => !isScrape || p.category === 'SCRAPE_ADMIN')
        .filter((p) => p.category !== 'API' || editUser.role === UserRoles.SUPER_ADMIN || editUser.is_service_account)
        .filter((p) => p.category !== 'SUPER_ADMIN' || editUser.role === UserRoles.SUPER_ADMIN)
        .filter(
          (p) =>
            p.category !== 'SCRAPE_ADMIN' || [UserRoles.SCRAPE_USER, UserRoles.SUPER_ADMIN].includes(editUser.role!),
        )
        .filter(
          (p) =>
            p.category != 'ADMIN' ||
            [UserRoles.SUPER_ADMIN.toString(), UserRoles.ADMIN.toString()].includes(editUser.role || UserRoles.USER),
        );
      setAllowedPermissions(filteredPermissions);
    });
  }, [editUser.is_service_account, editUser.role, isScrape, user.permissions, user.role]);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setPermissions([...permissions, event.target.name]);
      } else {
        setPermissions(permissions.filter((f) => f !== event.target.name));
      }
    },
    [permissions, setPermissions],
  );

  const renderSwitch = (feature: { name: string; key: string }) => {
    return (
      <div key={feature.key}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={permissions.includes(feature.key) ?? false}
              onChange={handleChange}
              name={feature.key}
            />
          }
          label={feature.name}
        />
      </div>
    );
  };

  const permissionGroup = (group: PermissionCategory, width: number) => {
    const permissionsToShow = allowedPermissions
      .filter((p) => p.category === group)
      .map((p) => ({
        key: p.name,
        name: t(p.name.toUpperCase()),
      }));
    if (!permissionsToShow.length) return null;
    return (
      <div
        style={{
          display: 'flex',
          width: `${width}%`,
          flexDirection: 'column',
        }}
      >
        <span>
          <b>{t(group)}</b>
        </span>

        {permissionsToShow.map(renderSwitch)}
      </div>
    );
  };
  const secondRowWidth = editUser.role != UserRoles.SUPER_ADMIN ? 50 : 33;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: '18px' }}>
        <strong>{t('ACCESS_TO')}</strong>
      </div>
      <div style={{ display: 'flex' }}>
        {permissionGroup(PermissionCategory.MONITORING, 50)}
        {permissionGroup(PermissionCategory.TAXATION, 50)}
      </div>

      <br />
      <div style={{ display: 'flex' }}>
        {permissionGroup(PermissionCategory.PROTOCOL, secondRowWidth)}
        {permissionGroup(PermissionCategory.OTHER, secondRowWidth)}
        {permissionGroup(PermissionCategory.API, secondRowWidth)}
      </div>
      <br />
      <div style={{ display: 'flex' }}>
        {permissionGroup(PermissionCategory.ADMIN, 33)}
        {permissionGroup(PermissionCategory.SUPER_ADMIN, 33)}
        {permissionGroup(PermissionCategory.SCRAPE_ADMIN, 33)}
      </div>
    </div>
  );
};
