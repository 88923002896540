import React, { useCallback, useMemo, useState } from 'react';
import { ResponsiveLineCanvas } from '@nivo/line';
import { styled } from '@mui/material';
import { CarResult, CarTurnover } from '@japieglobal/shared/src/types/car-types';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { ProgressIndicator } from '../../../../shared/src/components/loading-wrapper/progress-indicator';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { Colors } from '@japieglobal/shared/src/style/palettes/theme-colors';
import { getCarTurnover, getCarTurnoverByIds } from '@japieglobal/shared/src/api/services';

const margin = {
  left: 25,
  bottom: 25,
  top: 10,
  right: 5,
};

const Wrapper = styled(`div`)({
  height: '100px',
  width: '250px',
  position: 'relative',
});

interface CarTurnoverChartTinyProps {
  car: CarResult;
  column: 'etr' | 'itr';
  carTurnover?: CarTurnover;
  setCarTurnover: (turnover: CarTurnover) => void;
}

export const CarTurnoverChartTiny = (props: CarTurnoverChartTinyProps) => {
  const { car, column, carTurnover, setCarTurnover } = props;
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useDebouncedEffect(
    () => {
      if (car.id && !carTurnover) {
        if (car.id !== 100000000) {
          setIsLoading(true);
          getCarTurnover({ carId: String(car.id) })
            .then(setCarTurnover)
            .catch(snackbarErrorHandler)
            .finally(() => setIsLoading(false));
        } else {
          setIsLoading(true);
          getCarTurnoverByIds(car.window_car_ids!)
            .then((res) => {
              res.etr_list.pop();
              res.itr_list.pop();
              setCarTurnover({
                days: res.days_list,
                etr: res.etr_list.concat([car.stat!.turnover_ext!]),
                itr: res.itr_list.concat([car.stat!.turnover_int!]),
              });
            })
            .catch(snackbarErrorHandler)
            .finally(() => setIsLoading(false));
        }
      } else {
        setIsLoading(false);
      }
    },
    1000,
    [car, carTurnover, snackbarErrorHandler],
  );

  const mapTurnoverToChartData = useCallback((value: number, days: number) => {
    return { x: -days - 1, y: value };
  }, []);

  const data = useMemo(() => {
    if (carTurnover) {
      return [
        {
          id: column.toUpperCase(),
          data: [...carTurnover[column]].map((e, index) => mapTurnoverToChartData(e, carTurnover.days[index])),
        },
      ];
    }
    return undefined;
  }, [carTurnover, mapTurnoverToChartData, column]);

  const tickXValues = useMemo(() => {
    let maximumValue = 0;
    if (data && data?.length > 0 && data[0].data.length > 1) {
      maximumValue = data[0].data[0].x;
    } else return [0];
    const tickValues = [maximumValue];
    if (maximumValue < -60 && carTurnover?.days.includes(60)) tickValues.push(-60);
    if (maximumValue < -30 && carTurnover?.days.includes(30)) tickValues.push(-30);
    if (maximumValue < -15 && maximumValue > -45 && carTurnover?.days.includes(15)) tickValues.push(-15);
    return tickValues;
  }, [carTurnover?.days, data]);

  if (!isLoading && !carTurnover) {
    return <></>;
  }
  const tickYValues = [0, 1, 2, 3, 4, 5];

  return (
    <Wrapper>
      {carTurnover ? (
        <ResponsiveLineCanvas
          data={data!}
          margin={margin}
          axisLeft={{
            tickValues: tickYValues,
          }}
          colors={Colors.GREEN}
          axisBottom={{ tickValues: tickXValues }}
          gridYValues={tickYValues}
          enablePoints={false}
          yScale={{ type: 'linear', min: tickYValues[0], max: tickYValues[tickYValues.length - 1] }}
        />
      ) : (
        <ProgressIndicator />
      )}
    </Wrapper>
  );
};
