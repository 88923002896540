import { AppSearchParams } from '../../components';
import { instance } from './instance';

export enum CsmReportKeys {
  ACCOUNT_ID = 'account_id',
  CSM = 'csm',
  CSM_UPDATE = 'csm_update',
  DEALER = 'dealer',
  END_DATE_CONTRACT = 'end_date_contract',
  AUTO_ACCOUNT_HEALTH = 'auto_account_health',
  HEALTH = 'health',
  SCORE_CALLS = 'score_calls',
  SCORE_CALLS_DROP = 'score_calls_drop',
  SCORE_CONTRACT = 'score_contract',
  SCORE_MEETING = 'score_meeting',
  SCORE_SPREAD = 'score_spread',
  SCORE_TOTAL = 'score_total',
  VALUE = 'value',
  OWNER = 'owner',
  PM_UPDATE = 'pm_update',
  TOP_USER_CALLS_CHANGE = 'top_user_calls_change',
  TOP_USER_CALLS_0_30 = 'top_user_calls_0_to_30',
  TOP_USER_CALLS_30_60 = 'top_user_calls_30_to_60',
  TOP_USER_0_30 = 'top_user_0_to_30',
  TOP_USER_30_60 = 'top_user_30_to_60',
  TOTAL_CALLS_0_30 = 'total_calls_0_to_30',
  TOTAL_CALLS_30_60 = 'total_calls_30_to_60',
  TOTAL_CALLS_CHANGE = 'total_calls_change',
  TOTAL_USAGE_0_30 = 'total_usage_0_to_30',
  TOTAL_USAGE_30_60 = 'total_usage_30_to_60',
  TOTAL_USAGE_CHANGE = 'total_usage_change',
  USER_DEALER_RATIO_0_30 = 'user_dealer_ratio_0_tO_30',
  USER_DEALER_RATIO_30_60 = 'user_dealer_ratio_30_to_60',
  NEXT_MEETING = 'next_meeting',
  ARR_CATEGORY = 'arr_category',
  COMMENTS = 'comments',
  LAST_COMMENT_DATE = 'last_comment_date',
  STATUS = 'status',
}

export interface CsmReport {
  [CsmReportKeys.DEALER]: string;
  [CsmReportKeys.ARR_CATEGORY]: number | null;
  [CsmReportKeys.HEALTH]?: DealerAccountHealth;
  [CsmReportKeys.CSM]?: string;
  [CsmReportKeys.OWNER]: string | null;
  [CsmReportKeys.END_DATE_CONTRACT]: number | null;
  [CsmReportKeys.NEXT_MEETING]: number | null;
  [CsmReportKeys.CSM_UPDATE]: string | null;
  [CsmReportKeys.PM_UPDATE]: string | null;
  [CsmReportKeys.ACCOUNT_ID]: string;
  [CsmReportKeys.COMMENTS]?: [Message];
  [CsmReportKeys.LAST_COMMENT_DATE]?: string;
  [CsmReportKeys.STATUS]?: DealerAccountStatus;
  [CsmReportKeys.TOTAL_USAGE_CHANGE]: {
    [CsmReportKeys.VALUE]: number | null;
    [CsmReportKeys.TOTAL_USAGE_0_30]: number | null;
    [CsmReportKeys.TOTAL_USAGE_30_60]: number | null;
  };
  [CsmReportKeys.TOTAL_USAGE_0_30]: number | null;
  [CsmReportKeys.TOTAL_CALLS_CHANGE]: {
    [CsmReportKeys.VALUE]: number | null;
    [CsmReportKeys.TOTAL_CALLS_0_30]: number | null;
    [CsmReportKeys.TOTAL_CALLS_30_60]: number | null;
  };
  [CsmReportKeys.TOP_USER_CALLS_CHANGE]: {
    [CsmReportKeys.VALUE]: number | null;
    [CsmReportKeys.TOP_USER_CALLS_0_30]: number | null;
    [CsmReportKeys.TOP_USER_CALLS_30_60]: number | null;
    [CsmReportKeys.USER_DEALER_RATIO_0_30]: number | null;
    [CsmReportKeys.USER_DEALER_RATIO_30_60]: number | null;
    [CsmReportKeys.TOP_USER_0_30]: string | null;
    [CsmReportKeys.TOP_USER_30_60]: string | null;
  };
  [CsmReportKeys.AUTO_ACCOUNT_HEALTH]: {
    [CsmReportKeys.VALUE]: Health;
    [CsmReportKeys.SCORE_SPREAD]: number | null;
    [CsmReportKeys.SCORE_CALLS]: number | null;
    [CsmReportKeys.SCORE_CALLS_DROP]: number | null;
    [CsmReportKeys.SCORE_CONTRACT]: number | null;
    [CsmReportKeys.SCORE_MEETING]: number | null;
    [CsmReportKeys.SCORE_TOTAL]: number | null;
  };
}

export interface SalesReportRow {
  id: number;
  [CsmReportKeys.DEALER]: string;
  [CsmReportKeys.HEALTH]?: DealerAccountHealth;
  [CsmReportKeys.CSM]?: string;
  [CsmReportKeys.STATUS]?: DealerAccountStatus;
  [CsmReportKeys.COMMENTS]?: [Message];
  [CsmReportKeys.LAST_COMMENT_DATE]?: string;
}

export enum Health {
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
}

export enum DataType {
  MONITOR = 'monitoring',
  API = 'api',
  PURCHASE = 'purchase',
}

export enum DealerAccountHealth {
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
  BLACK = 'BLACK',
}
export enum DealerAccountStatus {
  PILOT = 'PILOT',
  NORMAL = 'NORMAL',
  FOCUS = 'FOCUS',
}

export interface UpdateDealerAccountBody {
  name: string;
  csm?: string;
  status?: DealerAccountStatus;
  health?: DealerAccountHealth;
}

export interface UpdateDealerAccountResponse {
  id: number;
  name: string;
  csm?: string;
  status?: DealerAccountStatus;
  health?: DealerAccountHealth;
  created_at: Date;
  updated_at: Date;
}
export interface Message {
  id: number;
  message: string;
  user: string;
  created_at: string;
  user_id: string;
}

interface CreateCsmDealerMessageBody {
  message: string;
  account_name: string;
  send_to_jira: boolean;
  ics_datetime: string;
}

export const getCsmDealerMessages = async (dealerAccountName: string): Promise<Message[]> =>
  instance.get(`/dealer-account-messages`, { params: { account_name: dealerAccountName } }).then((res) => res.data);

export const createCsmDealerMessage = async (body: CreateCsmDealerMessageBody): Promise<void> =>
  instance.post(`/dealer-account-message`, body);

export const updateDealerAccount = async (body: UpdateDealerAccountBody): Promise<UpdateDealerAccountResponse> =>
  instance.post(`/dealer-accounts`, body).then((res) => res.data);

export const deleteDealerAccount = async (id: number): Promise<UpdateDealerAccountResponse> =>
  instance.delete(`/dealer-accounts/${id}`).then((res) => res.data);

export const editCsmDealerMessage = async (messageId: number, body: CreateCsmDealerMessageBody): Promise<void> =>
  instance.put(`/dealer-account-message/${messageId}`, body);

export const deleteCsmDealerMessage = async (messageId: number): Promise<void> =>
  instance.delete(`/dealer-account-message/${messageId}`);

export const getCsmReport = async (params: AppSearchParams): Promise<CsmReport[]> =>
  instance.get('/csm/csm', { params }).then((res) => res.data);

export const getSalesReport = async (): Promise<SalesReportRow[]> => instance.get('/csm/sales').then((res) => res.data);
