import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { settings } from '@japieglobal/shared/src/settings';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  applyCustomDataCars,
  deleteFile,
  downloadFile,
  existsFile,
  uploadFile,
  UploadType,
  UserRoles,
} from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';

const useStyles = makeStyles(() => ({
  main: {
    padding: 20,
    margin: 20,
  },
  table: {
    width: 400,
  },
  container: {
    color: '#616161',
  },
  dropMessage: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dropImage: {
    display: 'block',
  },
  formControl: {
    minWidth: 150,
    paddingTop: 5,
  },
}));
const Wrapper = styled(`div`)({ marginTop: '30px' });

export const UploadFileView = React.memo(() => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const { snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const suffix = settings().country == 'nl' ? '' : '_ref';
  const filenames = [
    ['VIN', `${user.dealer}_vin.csv`, `example_vin${suffix}.csv`, UploadType.VIN],
    ['Leads', `${user.dealer}_leads.csv`, `example_leads${suffix}.csv`, UploadType.LEADS],
    ['Purchase', `${user.dealer}_purchase.csv`, `example_purchase${suffix}.csv`, UploadType.PURCHASE],
    ['Location', 'inkopers.csv', `example_location${suffix}.csv`, UploadType.LOCATION],
    ['Supply Type: Demo', `${user.dealer}_is_demo.csv`, `example_supply_type${suffix}.csv`, UploadType.DEMO],
    ['Supply Type: New', `${user.dealer}_is_new.csv`, `example_supply_type${suffix}.csv`, UploadType.NEW],
    [
      'Stock Days Client',
      'stock_days_client.csv',
      `example_stock_days_client${suffix}.csv`,
      UploadType.STOCK_DAYS_CLIENT,
    ],
    ['Car Attributes', 'car_attributes.csv', `example_car_attributes${suffix}.csv`, UploadType.CAR_ATTRIBUTES],
  ];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [uploadType, setUploadType] = useState<UploadType>(filenames[0][3]);
  const [fileExists, setFileExists] = useState<boolean>(false);
  const [append, setAppend] = useState<boolean>(false);

  function exampleFile(type: UploadType, filenames) {
    return filenames.find((data) => data[3] === type)[2];
  }

  const refreshFileExists = useCallback(() => {
    setFileExists(false);
    existsFile(user.dealer, { upload_type: uploadType }).then((res) => {
      setFileExists(res['exists']);
    });
  }, [uploadType, user]);

  useEffect(() => {
    refreshFileExists();
  }, [refreshFileExists]);

  const refreshCars = useCallback(() => {
    setLoading(true);
    applyCustomDataCars(user.dealer)
      .then(() => {
        snackbarSuccessMessage(t('REFRESH_SUCCESS'));
      })
      .finally(() => setLoading(false));
  }, [user, snackbarSuccessMessage, t]);

  const removeFile = useCallback(() => {
    deleteFile(user.dealer, { upload_type: uploadType })
      .then(() => {
        snackbarSuccessMessage(t('REMOVE_SUCCESS'));
        refreshFileExists();
      })
      .catch(() => {
        snackbarErrorMessage(t('REMOVE_ERROR'));
      });
  }, [uploadType, user, snackbarErrorMessage, snackbarSuccessMessage, t, refreshFileExists]);

  const handleDownloadFile = useCallback(() => {
    downloadFile(user.dealer, { upload_type: uploadType })
      .then(async (res) => {
        const blob = await res.data;
        const a = document.createElement('a'); // TODO: See downloadfile function
        a.href = URL.createObjectURL(blob);
        a.download = (res.headers['content-disposition']?.split('filename=')[1] || 'file').replaceAll('"', '');
        a.click();
        snackbarSuccessMessage(t('DOWNLOAD_SUCCESS'));
      })
      .catch(() => {
        snackbarErrorMessage(t('DOWNLOAD_ERROR'));
      });
  }, [uploadType, user, snackbarErrorMessage, snackbarSuccessMessage, t]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setLoading(true);
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('file', file, file.name);
      uploadFile(formData, user.dealer, { upload_type: uploadType, append })
        .then(async (res) => {
          if (!res['success']) throw Error();
          snackbarSuccessMessage(t('UPLOAD_SUCCESS'));
          refreshFileExists();
        })
        .catch(() => {
          snackbarErrorMessage(t('UPLOAD_ERROR'));
        })
        .finally(() => setLoading(false));
    },
    [snackbarErrorMessage, snackbarSuccessMessage, t, uploadType, user, refreshFileExists, append],
  );
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop });

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#96BF31',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const baseStyle = {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Wrapper>
      <Grid2 container spacing={3}>
        <Grid2 className={classes.container} size={9}>
          <div {...getRootProps({ style })} className={classes.main}>
            <input {...getInputProps()} />
            <div className={classes.dropMessage}>
              <img src="/dragdrop.png" width={160} alt="drag-n-drop" />
              <div>{t('UPLOAD_DROP_MESSAGE')}</div>
            </div>
          </div>
        </Grid2>
        <Grid2 className={classes.container} size={3}>
          <FormControl margin="normal" className={classes.formControl} fullWidth>
            <InputLabel>{t('File')}</InputLabel>
            <Select value={uploadType} onChange={(e) => setUploadType(e.target.value as UploadType)} name="filename">
              {filenames.map(([label, path, , type]) => (
                <MenuItem key={type} value={type}>
                  {label} ({path})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            label={t('APPEND')}
            control={<Switch color="primary" checked={append} onChange={() => setAppend(!append)} name="append" />}
          />
          <Button href={`/upload-input/${exampleFile(uploadType, filenames)}`} startIcon={<GetAppIcon />}>
            {t('FILE_VALUATION_INPUT_EXAMPLE')}
          </Button>
          {fileExists && (
            <>
              <Button onClick={() => handleDownloadFile()} startIcon={<GetAppIcon />}>
                {t('DOWNLOAD')}
              </Button>
              <Button onClick={() => removeFile()} startIcon={<DeleteIcon />}>
                {t('REMOVE')}
              </Button>
            </>
          )}
          {user.role === UserRoles.SUPER_ADMIN && (
            <Button onClick={() => refreshCars()} startIcon={<RefreshIcon />}>
              {t('REFRESH_CARS')}
            </Button>
          )}
          {loading && <CircularProgress />}
        </Grid2>
      </Grid2>
    </Wrapper>
  );
});
