import { Box, styled } from '@mui/material';
import React from 'react';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { BoxFlexColumn, CarTableCell } from '../result-table-styles';
import { formatDecimalAsPercent, formatKNotation } from '../../../utils/number-format';
import { compareMemoProps } from '../../../utils/compare-react-props';
import { ResultTableAdditionalInformation } from '../../../taxation-and-results-shared/result-table-additional-information-cell/result-table-additional-information-cell';
import { ResultTaxesCell } from '../../../taxation-and-results-shared/result-taxes-cell/result-taxes-cell';
import { ResultTableTrace } from '../../../taxation-and-results-shared/buttons/result-table-trace-button';
import { settings } from '@japieglobal/shared/src/settings';

interface ResultPriceInformationProps {
  car: CarResult;
}

const BoxFlexRowWithFontSize = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '11px',
});

export const ResultPriceInformation = React.memo(
  ({ car }: ResultPriceInformationProps) => (
    <CarTableCell width={140}>
      <BoxFlexColumn>
        <BoxFlexRowWithFontSize>
          <span style={{ marginRight: '5px' }}>P:</span>
          <span>
            {settings().currencySymbol}
            {formatKNotation(car.price?.local)}
          </span>
        </BoxFlexRowWithFontSize>
        <BoxFlexRowWithFontSize>
          <span style={{ marginRight: '5px' }}>D:</span>
          <span>{formatDecimalAsPercent(car.rank?.target_perc)}</span>
        </BoxFlexRowWithFontSize>
        <Box flexDirection="row" display="flex-start" justifyContent="center">
          <ResultTableAdditionalInformation car={car} />
          <ResultTableTrace car={car} />
          <ResultTaxesCell car={car} />
        </Box>
      </BoxFlexColumn>
    </CarTableCell>
  ),
  compareMemoProps(['car']),
);
