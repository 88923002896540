import React, { useCallback, useContext, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { createPriceProposal, removePriceProposal } from '@japieglobal/shared/src/api/services';
import { ConfirmationDialog } from '@japieglobal/shared/src';
import { compareMemoProps } from '../../../utils/compare-react-props';
import { CarTableCell } from '../result-table-styles';
import { UserContext } from '@japieglobal/shared/src/user-context';

const LockImage = styled('img')({
  cursor: 'pointer',
  width: '22px',
  height: '22px',
  margin: '5px',
});

interface ResultPriceProposalLockCell {
  targetPrice: string | undefined;
  car: CarResult;
  isCompetitorView: boolean | undefined;
  closeDialog?: () => void;
  reloadCarList?: (id: number) => void;
}
export const ResultPriceProposalLockCell = React.memo(
  ({
    isCompetitorView,
    reloadCarList = () => undefined,
    car,
    targetPrice,
    closeDialog = () => undefined,
  }: ResultPriceProposalLockCell) => {
    const { user } = useContext(UserContext);

    const hasRightForPriceProposal = useMemo(() => user.permissions.includes('priceProposal'), [user.permissions]);
    const { snackbarErrorHandler } = useSnackbarErrorHandler();

    const [showLockPrice, setShowLockPrice] = useState(false);
    const closeShowLockPrice = React.useCallback(() => setShowLockPrice(false), []);
    const handleCreatePriceProposal = React.useCallback(() => {
      if (car && targetPrice && Number.isFinite(Number(targetPrice))) {
        createPriceProposal({
          id: car.id!,
          body: {
            rank_current_value: car.price?.local,
            rank_target_value: Number(targetPrice),
          },
        })
          .then(() => {
            reloadCarList(car.id!);
            closeDialog();
          })
          .catch(snackbarErrorHandler);
      }
    }, [car, closeDialog, reloadCarList, snackbarErrorHandler, targetPrice]);
    const lockPrice = useCallback(() => {
      if (car) {
        setShowLockPrice(true);
      }
    }, [car]);

    const [showUnLockPrice, setShowUnLockPrice] = useState(false);
    const closeShowUnLockPrice = React.useCallback(() => setShowUnLockPrice(false), []);

    const handleRemovePriceProposal = React.useCallback(() => {
      if (car && car.proposal?.id !== undefined) {
        removePriceProposal({
          id: car.proposal?.id,
        })
          .then(() => {
            reloadCarList(car.id!);
          })
          .catch(snackbarErrorHandler);
      }
    }, [car, reloadCarList, snackbarErrorHandler]);

    const { t } = useTranslation();

    const unlockPrice = useCallback(() => {
      if (car && car.proposal?.id !== undefined) {
        setShowUnLockPrice(true);
      }
    }, [car]);

    const lastPriceProposal = useMemo(() => {
      let result;
      if (car.proposal?.created) {
        const dateCreatedCarProposal = new Date(car.proposal.created);
        const today = new Date();
        const differenceInTime = today.getTime() - dateCreatedCarProposal.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        result = Math.round(differenceInDays);
      }
      return result;
    }, [car.proposal?.created]);

    return (
      <CarTableCell>
        {hasRightForPriceProposal ? (
          <>
            {isCompetitorView && car.has_price_proposal !== true && (
              <LockImage src="/images/icon-topic-open.svg" onClick={lockPrice} />
            )}
            {car.has_price_proposal && (
              <>
                <LockImage src="/images/icon-topic-closed.svg" onClick={unlockPrice} />
                <div style={{ fontSize: '8px' }}>{lastPriceProposal} dgn</div>
              </>
            )}
            {showLockPrice && (
              <ConfirmationDialog
                closeDialog={closeShowLockPrice}
                title={t('PRICE_PROPOSAL')}
                question={t('ARE_YOU_SURE_TO_CREATE_PRICE_PROPOSAL')}
                yesAction={handleCreatePriceProposal}
              />
            )}
            {showUnLockPrice && (
              <ConfirmationDialog
                closeDialog={closeShowUnLockPrice}
                title={t('PRICE_PROPOSAL')}
                question={t('ARE_YOU_SURE_TO_DELETE_PRICE_PROPOSAL')}
                yesAction={handleRemovePriceProposal}
              />
            )}
          </>
        ) : undefined}
      </CarTableCell>
    );
  },
  compareMemoProps(['car']),
);
